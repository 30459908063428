<template>
  <div class="container">
    <div class="promotionBox">
      <div class="paddingPub pro_title">
        <div class="head">{{ $t('borrow.title') }}</div>
        <div class="desc">{{ $t('borrow.title') }}</div>
      </div>
      <div class="paddingPub contentBox">
        <div class="content">
          <p class="headTop">
            <img src="@/assets/images/borrow.jpg">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Borrow',
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.promotionBox {
  position: relative;
  padding-bottom: 34px;
  &::after{
    width: 66%;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 2px dashed #000B8C;
  }
  .pro_title{
    color: #ffffff;
    line-height: 58px;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: #091490;
    background-image: url("../assets/images/policy_bg.png");
    background-position: right -4%;
    background-repeat: no-repeat;
    background-size: 35% 130%;
    .head{
      font-size: 32px;
      font-weight: bold;
    }
    .desc{
      font-size: 16px;
    }
  }
}
.contentBox{
  padding-top: 4%;
  padding-bottom: 8%;
  color: #303030;
  .title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .content {
    .headTop{
      margin-bottom: 16px;
    }
    strong{
      display: inline-block;
      margin-bottom: 8px;
      font-size: 15px;
    }
    ol {
      color: #000000;
      line-height: 28px;
    }
  }
}
@media screen and (max-width: 576px) {
  .promotionBox{
    padding-bottom: 0;
    &::after{
      width: 0;
    }
  }
  .contentBox{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>
